
import './App.css';
import 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import Corpo from './componentes/corpo';
import Navb from './Navbar';

function App() {
  return (
    
    <div className="App">
      <Corpo />
    </div>
  );
}

export default App;
